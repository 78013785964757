import React from "react"
import "../stylesheets/general.css"
import { Content, Layout, ProjectForm } from "../components"
import SEO from "../components/SEO"
import { H1 } from "../elements"
const StartAProject = () => {
  return (
    <Layout backgroundColor="gray" hasHeader={false}>
      <SEO title={"Start A Project"} description={"Want us to work on your project?"} />
      <Content>
        <H1 margin="0 0 70px 0" large>
          Want us to work on <br /> your project?
        </H1>
        <ProjectForm />
      </Content>
    </Layout>
  )
}

export default StartAProject
